import React, { useRef } from 'react'



import './Background.css';



export const Background = ({imagenesBack=null}) => {


/*
	const [imagenesBack] = useState([
		"https://altorneo.com/site/assets/background/back_02.jpeg",
		"https://altorneo.com/site/assets/background/back_01.jpg",
		"https://altorneo.com/site/assets/background/back_07.jpg",
		"https://altorneo.com/site/assets/background/back_05.jpg",
		"https://altorneo.com/site/assets/background/back_03.jpg",
		"https://altorneo.com/site/assets/background/back_06.jpeg",
		"https://altorneo.com/site/assets/background/back_04.jpg"
	]);
*/


	const fondoActual = useRef(0);


	//	let listaImagenes = document.querySelector(".mySlides");
	//	if (listaImagenes !== null) {
	if (imagenesBack.length > 0) {
		let myTimer = setInterval(() => {
			imagenesBack.forEach((imagen, index) => {
				let img = document.querySelector(".slide_" + index);
				if (img !== null) {
					if (index !== fondoActual.current) {
						img.style.opacity = "0";
						img.style.zIndex = "0";
					} else {
						img.style.opacity = "1"
						img.style.zIndex = "1";
					}
				} else {
					clearInterval(myTimer);
				}
			})
			fondoActual.current++;
			if (fondoActual.current >= imagenesBack.length) {
				fondoActual.current = 0;
			}
		}, 5000);

	}



	return (
		<div className="slideshow-container">
			<div className="slideshow-inner">
				{
					imagenesBack.map((imagen, index) => (
						<div
							style={index === 0 ? { opacity: "1" } : {}}
							className={`mySlides ${'slide_' + index}`}
							key={index}>
							<img
								src={imagen}
								alt="altorneo.com" />
						</div>
					))
				}
			</div>
		</div>
	)
}