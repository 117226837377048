import React, { useState } from 'react'



import { ModalContactanos } from './modals/ModalContactanos'
import { URL_SVG } from '../utils/config';



export const Footer = () => {



	const [showModal, setShowModal] = useState(false);
	const onShow = (value) => {
		setShowModal(value);
	};



	return (
		<>
			<div className="contactanos">
				<img src={URL_SVG + 'email.svg'} alt='Contactanos' title='Contactanos' onClick={() => setShowModal(true)} />
				<h3>Dejanos un mensaje</h3>
			</div>
			<footer>
				<div className="footer">
					<div className="col-lg-12">
						<div className="text-center">Consultas : &nbsp;  &nbsp;
							<a href="mailto:contacto@altorneo.com">contacto@altorneo.com</a>
						</div>
					</div>
				</div>
			</footer>
			{
				showModal &&
				<ModalContactanos
					show={showModal}
					onShow={onShow}
				/>
			}
		</>
	)
}
